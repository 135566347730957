
import Vue, { VueConstructor } from "vue";
import KCrudTable, {
  CrudTableHeader,
} from "@/modules/crudTable/components/KCrudTable.vue";
import { ExportType, PaginatedRequest } from "@/application/api/getPaginated";
import { mapGetters } from "vuex";
import RequiredClientDialog from "@/modules/client/components/RequiredClientDialog.vue";
import {
  clientStockBatchIndex,
  StockBatchIndexItem,
} from "@/modules/stock/api/stockBatch";
import ExportButton from "@/components/ExportButton.vue";
import StockBatchReportFilter from "@/modules/stock/components/StockBatchReportFilter.vue";

interface ComponentData {
  headers: CrudTableHeader[];
  selected: Partial<StockBatchIndexItem> & { clientId: number; id: number }[];
  exports: ExportType[];
  stockBatchData: StockBatchIndexItem[];
  filterComponent: VueConstructor;
}

export default Vue.extend({
  name: "StockBatchReportTable",
  components: {
    ExportButton,
    RequiredClientDialog,
    KCrudTable,
  },
  data: (): ComponentData => ({
    headers: [
      { value: "dimProduct.articleNumber", sortable: false },
      { value: "dimProduct.productName", sortable: false },
      { value: "amount", sortable: false },
      { value: "sku", sortable: false },
      { value: "manualAmount", sortable: false },
      { value: "hu", sortable: false },
      { value: "status", sortable: true },
      { value: "lotNumber", sortable: false },
      {
        value: "thtDate",
        type: "date",
        sortable: false,
        typeParameters: {
          dateType: "DD MMM YYYY",
        },
      },
    ],
    selected: [],
    exports: [],
    stockBatchData: [],
    filterComponent: StockBatchReportFilter,
  }),
  computed: {
    ...mapGetters("authorisation", ["client", "isClient"]),
    ...mapGetters("crudTable", ["getRouteSetting"]),
  },
  watch: {
    client: {
      handler() {
        this.refreshTable();
      },
      deep: true,
    },
  },
  methods: {
    async stockBatchIndex(data: PaginatedRequest) {
      if (!this.client?.id) throw "Client id is not set";
      const response = await clientStockBatchIndex(data, this.client?.id);
      this.stockBatchData = response.data.data;
      this.exports = response.data.export ?? [];
      return response;
    },
    refreshTable(): void {
      (this.$refs.table as any).getData();
    },
  },
});
