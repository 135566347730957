<template>
  <v-menu v-model="showMenu" offset-y>
    <template v-slot:activator="{ on }">
      <div class="d-flex float-right elevation-2 rounded" v-on="on">
        <!-- selected action -->
        <v-btn
          :disabled="disabled"
          color="accent"
          :class="{ 'rounded-r-0': !hideDropDown }"
          elevation="0"
          :retain-focus-on-click="hideDropDown"
          :ripple="hideDropDown"
          @click.stop="selectItemHandler(items.length === 1 ? items[0] : null)"
        >
          {{ $t("actions.exportText") }}
        </v-btn>
        <VDivider class="mx-0" vertical color="white" v-if="!hideDropDown" />
        <!-- open menu -->
        <v-btn
          v-if="!hideDropDown"
          :disabled="disabled"
          color="accent"
          elevation="0"
          class="dropdown-button rounded-l-0"
          :min-width="20"
          :width="40"
          @click.stop="showMenu = !showMenu"
        >
          <v-icon size="18">$dropdown</v-icon>
        </v-btn>
      </div>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in items"
        :key="index"
        @click="selectItemHandler(item)"
      >
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "KDropdownButton",
  inheritAttrs: false,
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    hideDropDownForSingleItem: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      required: true,
    },
  },
  data: () => ({
    showMenu: false,
    selectedValue: null,
  }),
  computed: {
    hideDropDown() {
      return this.hideDropDownForSingleItem && this.items.length <= 1;
    },
  },
  mounted() {
    this.selectedValue = this.value;
  },
  watch: {
    value(value) {
      this.selectedValue = value;
    },
  },
  methods: {
    selectItemHandler(item) {
      if (!item) {
        this.showMenu = true;
        return;
      }
      this.selectedValue = item;
      this.showMenu = false;
      this.$emit("input", this.selectedValue);
      this.$emit("click");
    },
  },
});
</script>
