
import Vue, { PropType } from "vue";
import KDropdownButton from "@/components/KDropdownButton.vue";
import { sendBlobToBrowserDownload } from "@/application/util/downloadFile";
import { get } from "@/application/api";
import { convertCamelToSnake } from "@/application/util/keyConverter";
import { ExportType } from "@/application/api/getPaginated";
import { mapGetters } from "vuex";
import { RouteSetting } from "@/modules/crudTable/store/crudTableStore";

interface ComponentData {
  selectedItem: null | ExportType;
}

export default Vue.extend({
  name: "ExportButton",
  components: { KDropdownButton },
  props: {
    items: {
      type: Array as PropType<ExportType[]>,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    hasFiltering: {
      type: Boolean,
      default: true,
    },
    hasSearchValue: {
      type: Boolean,
      default: true,
    },
  },
  data: (): ComponentData => ({
    selectedItem: null,
  }),
  computed: {
    ...mapGetters("crudTable", ["getRouteSetting"]),
    currentRouteSettings(): RouteSetting {
      return this.getRouteSetting(this.$route.name);
    },
    computedItems() {
      return this.items
        ? this.items
            .map((item: ExportType) => ({
              title: this.$t(`actions.export.${item.title}`),
              url: item.url,
            }))
            .reverse()
        : [];
    },
  },
  methods: {
    getUrlParameters(url: URL) {
      const storedFilters = this.currentRouteSettings?.pagination?.params;
      const filteredFilters =
        storedFilters && this.hasFiltering
          ? Object.entries(storedFilters)
              .map(([key, value]) => {
                return [convertCamelToSnake(key), value];
              })
              .reduce((acc, [key, value]) => {
                if (value === null || value === undefined) return acc;
                return { ...acc, [key]: value };
              }, {})
          : {};

      const searchField: Record<string, string> = {};
      const searchValue = this.currentRouteSettings?.pagination?.search;

      if (searchValue && this.hasSearchValue) {
        searchField.search = searchValue;
      }

      return new URLSearchParams({
        ...Object.fromEntries(url.searchParams),
        ...filteredFilters,
        ...searchField,
      }).toString();
    },
    async exportHandler() {
      if (!this.selectedItem?.url) return;
      const url = new URL(`http://` + this.selectedItem.url);
      const parameters = this.getUrlParameters(url);
      const fullUrl =
        url.hostname + url.pathname + (parameters ? `?${parameters}` : "");

      const response = await get(fullUrl, {
        responseType: "blob",
      });

      sendBlobToBrowserDownload(
        `export-${this.selectedItem.title}`,
        "xlsx",
        response.data
      );
    },
  },
});
