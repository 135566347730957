import {
  getPaginated,
  PaginatedRequest,
  PaginatedResponse,
} from "@/application/api/getPaginated";
import { AxiosResponse } from "axios";
import mapIndexResponseErrors from "@/application/util/mapIndexResponseErrors";

export interface StockBatchIndexItem {
  id: number;
  dimProduct: {
    articleNumber?: string;
    productName?: string;
  };
  amount: number;
  sku: string;
  manualAmount: number;
  hu: string;
  status: string;
  lotNumber: string;
  thtDate: string;
}

export const clientStockBatchIndex = async (
  data: PaginatedRequest,
  clientId: number
): Promise<AxiosResponse<PaginatedResponse<StockBatchIndexItem[]>>> => {
  const response = await getPaginated(`client/${clientId}/stock/batch`, data);
  response.data.data = mapIndexResponseErrors(response.data.data);

  return response;
};
