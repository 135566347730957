
import Vue from "vue";
import ClientAutocomplete from "@/modules/client/components/ClientAutocomplete.vue";
import { mapGetters, mapMutations } from "vuex";

export default Vue.extend({
  name: "ClientSelector",
  components: { ClientAutocomplete },
  computed: {
    ...mapGetters("authorisation", ["client", "isClient", "clients"]),
  },
  methods: {
    ...mapMutations("authorisation", ["setClient"]),
  },
});
