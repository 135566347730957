export default function mapIndexResponseErrors(
  data: {
    errors: { message: string; errors: Record<string, string[]> };
  }[]
): {
  errors?: string;
}[] {
  return data.map((item) => {
    return {
      ...item,
      errors:
        item.errors && item.errors.errors
          ? Object.values(item.errors.errors)
              .map((error) => error.join(", "))
              .join("\n\r")
          : undefined,
    };
  });
}
