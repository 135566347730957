
import Vue from "vue";
import ClientSelector from "@/modules/client/components/ClientSelector.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "RequiredClientDialog",
  components: { ClientSelector },
  computed: {
    ...mapGetters("authorisation", ["client", "isClient"]),
  },
  props: {
    isOptionalForNonClients: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isDialogOpen: false,
  }),
  watch: {
    client: {
      handler() {
        if (this.isOptionalForNonClients && !this.isClient) return;

        if (!this.client?.id) {
          this.isDialogOpen = true;
          return;
        }
        this.isDialogOpen = false;
        return;
      },
      deep: true,
      immediate: true,
    },
  },
});
