var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('KSelect',_vm._g(_vm._b({attrs:{"items":[
      { text: _vm.$t('stockBatchReport.status.sellable'), value: 'SELLABLE' },
      { text: _vm.$t('stockBatchReport.status.damaged'), value: 'DAMAGED' },
      { text: _vm.$t('stockBatchReport.status.reserved'), value: 'RESERVED' },
      { text: _vm.$t('stockBatchReport.status.blocked'), value: 'BLOCKED' },
      { text: _vm.$t('stockBatchReport.status.expired'), value: 'EXPIRED' },
    ],"field":"stockBatchStatus","clearable":""}},'KSelect',_vm.$attrs,false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }